<template>
    <b-overlay :show='showLoader'>
        <div style="position: relative;">
            <div class="text-right top-navbar">
                <b-row class="m-0 d-flex justify-content-xl-end" align-v="center" style="font-size: 13px;">
                    <b-col cols="12" sm="6" md="3" xl="2" class="mt-1 mt-xl-0 pl-0">
                        <Select v-model="filter.listing_type" :options="listingTypes" label="title" placeholder="Vrsta posla" @change="listingTypeChange"/>
                    </b-col>
                    <b-col cols="12" sm="6" md="3" xl="3" class="mt-1 mt-xl-0 pl-0 pl-xl-1">
                        <Select v-model="filter.region" :options="regionOption" label="title" placeholder="Lokacija" @change="regionChanged"/>
                    </b-col>
                    <b-col cols="12" sm="6" md="3"  xl="2" class="mt-1 mt-xl-0 pl-0 pl-xl-1">
                        <Select v-model="filter.price" :options="priceOption" label="title" placeholder="Cena" @change="priceChange"/>
                    </b-col>
                    <b-col cols="12" sm="3" md="2" xl="2" class="mt-1 mt-xl-0 pl-0 pl-xl-1">
                        <b-button block pill class="realestate-button-search" @click="loadRealEstates">
                            IŠČI
                            <feather-icon class="ml-1" size="1.2x" icon="SearchIcon" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <b-row class="mt-3">
                <template v-if="realEstateItems && realEstateItems.length">
                    <b-col v-for="realEstate of realEstateItems" :key="realEstate.id" sm="12" md="6" lg="4" class="mb-3">
                        <real-estate-card :realEstate="realEstate" :all="true"/>
                    </b-col>
                </template>
                <template v-else>
                    <b-col class="text-center">
                        <h3>V tej kategoriji ni oglasov</h3>
                    </b-col>
                </template>
            </b-row>
        </div>
        <div class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start" v-if="pagination && pagination.total_pages > 1">
            <b-pagination 
                id="realEstatePagination" 
                hide-goto-end-buttons
                v-model="pagination.current_page" 
                :total-rows="pagination.total_items" 
                :per-page="pagination.per_page" 
                next-class="hidden" 
                prev-class="hidden"/>
        </div>
        <news-carousel class="mt-2"/>
    </b-overlay>
</template>

<script>
    import RealEstateCard from '../../Components/RealEstateCard'
    import {BOverlay, BRow, BCol, BPagination, BButton} from 'bootstrap-vue'
    import Select from '@/views/Components/Select'
    import Vue from 'vue'
    import newsCarousel from '@/views/Components/NewsCarousel'

    export default {
        components: {
            Select,
            RealEstateCard,
            BOverlay,
            BRow,
            BCol,
            BPagination,
            BButton,
            newsCarousel
        },
        props: {
            listing_type: {
                type: String
            }
        },
        data() {
            return {
                filter: {
                    listing_type: '',
                    region: '', 
                    price: ''
                },
                regionOption: [
                    {title: 'Vse', value: { }},
                    {title: 'Osrednjeslovenska', value: {region: '1'}},
                    {title: 'Gorenjska', value:  {region: '2'}},
                    {title: 'Goriška', value: {region: '3'}},
                    {title: 'Obalno - kraška', value: {region: '4'}},
                    {title: 'Primorsko - notranjska', value: {region: '5'}},
                    {title: 'Jugovzhodna', value: {region: '6'}},
                    {title: 'Posavska', value: {region: '7'}},
                    {title: 'Zasavska', value: {region: '8'}},
                    {title: 'Savinjska', value: {region: '9'}},
                    {title: 'Koroška', value: {region: '10'}},
                    {title: 'Podravska', value: {region: '11'}},
                    {title: 'Pomurska', value: {region: '12'}}

                ],
                priceOption: [
                    {title: 'Vse', value: { }},
                    {title: '0 - 50k', value: { from: '0', to: '5000000'}},
                    {title: '50k - 100k', value: { from: '5000000', to: '10000000'}},
                    {title: '100k - 150k', value: { from: '10000000', to: '15000000'}},
                    {title: '150k - 250k', value: { from: '15000000', to: '25000000'}},
                    {title: '250k - 400k', value: { from: '25000000', to: '40000000'}},
                    {title: '400k - 800k', value: { from: '40000000', to: '80000000'}},
                    {title: '800k+', value: { from: '8000000'}}


                ],
                realEstateItems: [],
                showLoader: false,
                rows: 0,
                perPage: 12,
                currentPage: 1,
                listingTypes: [
                    {title: 'Vse', value: { }},
                    { title:'Prodaja', value: {type: '0'}},
                    { title:'Oddaja', value: {type: '1'}},
                    { title:'Najem', value: {type: '2'}},
                    { title:'Zamenjava', value: {type: '3'}}
                ],
                filterOptions: [
                    { value: 'date_posted.sort=-1', text: 'Najnovejše' },
                    { value: 'date_posted.sort=1', text: 'Najstarejše' },
                    { value: 'price.sort=1', text: 'Najnižja cena' },
                    { value: 'price.sort=-1', text: 'Najvišja cena' }
                ],
                selectedFilterOption: 'date_posted.sort=-1',
                pagination: null,
                selectedListingTypeIDs: [],
                slider_items: [{title: 'Cenovni razpon'}],
                sliderValues: {
                    value: [0, 100],
                    min: 0,
                    max: 1000000000,
                    interval: 1
                },
                isSettingSlideValuesAllowed: true,
                created: false,
                checkType: [],
                backForwardNavigation: false
            }
        },
        methods: {
            priceChange(val) {
                const queryParams = Object.assign({}, this.$route.query)
                let same_to = false
                let same_from = false

                if (queryParams['price_to'] === undefined && val.value.to === undefined) return

                if (val.value && val.value.to) {
                    if (queryParams['price_to'] === val.value.to) {
                        same_to = true
                    }

                    queryParams['price_to'] = val.value.to
                } else {
                    Vue.delete(queryParams, 'price_to')
                }

                if (val.value && val.value.from) {
                    if (queryParams['price_from'] === val.value.from) {
                        same_from = true
                    }

                    queryParams['price_from'] = val.value.from
                } else {
                    Vue.delete(queryParams, 'price_to')
                    Vue.delete(queryParams, 'price_from')
                }

                if (!(same_from && same_to)) {
                    this.$router.replace({ 'query': queryParams })
                    this.loadRealEstates()
                }
            },
            regionChanged(val) {
                const queryParams = Object.assign({}, this.$route.query)
                let same = false

                if (queryParams['region'] === undefined && val.value.region === undefined) return

                if (val.value && val.value.region) {

                    if (queryParams['region'] === val.value.region) {
                        same = true
                    }

                    queryParams['region'] = val.value.region
                } else {
                    Vue.delete(queryParams, 'region')
                }

                if (!same) {
                    this.$router.replace({ 'query': queryParams })
                    this.loadRealEstates()
                }
            },
            listingTypeChange(val) {
                const queryParams = Object.assign({}, this.$route.query)
                let same = false

                if (queryParams['listing_type'] === undefined && val.value.type === undefined) return

                if (val.value && val.value.type) {
                    if (queryParams['listing_type'] === val.value.type) {
                        same = true
                    }

                    queryParams['listing_type'] = val.value.type
                } else {
                    Vue.delete(queryParams, 'listing_type')
                }

                if (!same) {
                    this.$router.replace({ 'query': queryParams })
                    this.loadRealEstates()
                }
            },
            getSelectedFilterOptionQueryParam() {
                // setting default sort value (date_posted descending)
                let sortQueryParam = this.filterOptions[0].value 
                const split = this.selectedFilterOption.split('=')
                if (split.length !== 2) return sortQueryParam

                // if there is filter option in query, then this.$route.query[split[0]] won't return undefined,
                // but will return either 1 or -1 (asc or desc), then we check if it matches 1 or -1
                if (this.$route.query[split[0]] && Number(this.$route.query[split[0]]) === Number(split[1])) {
                    sortQueryParam = this.selectedFilterOption
                }

                return sortQueryParam
            },
            // getListingTypeQueryParams() {
            //     if (!this.created && this.$route.query.listing_type) {
            //         for (const id of this.$route.query.listing_type) {
            //             this.selectedListingTypeIDs.push(Number(id))
            //             this.checkType.push(Number(id))
            //         }

            //         // remove duplicates (if listing_type query param was set and user reloaded page, IDs were duplicating (1->2->4->8->16->32->64...),
            //         // so URL was full of duplicated listing_types)
            //         this.selectedListingTypeIDs = [...new Set(this.selectedListingTypeIDs)]
            //         this.checkType = [...new Set(this.checkType)]
            //     }
                
            //     let selectedListingTypesQueryParam = ''
            //     if (this.selectedListingTypeIDs.length) {
            //         const listingTypeQuery = []
            //         for (const id of this.selectedListingTypeIDs) {
            //             listingTypeQuery.push(`&listing_type.[]=${id}&`)
            //         }
            //         selectedListingTypesQueryParam = listingTypeQuery.join('')

            //         if (this.$route.query.listing_type && this.$route.query.listing_type.length === this.selectedListingTypeIDs.length) {
            //             return selectedListingTypesQueryParam
            //         }

            //         this.$router.replace({'path': this.$route.fullPath, 'query': { 'listing_type': this.selectedListingTypeIDs.map(x => x) }})
            //     } else if (this.$route.query.listing_type) {
            //         const query = Object.assign({}, this.$route.query)
            //         delete query.listing_type
            //         this.$router.replace({ query })
            //     }
            //     return selectedListingTypesQueryParam
            // },
            getQueryParams() {

                const page = this.$route.query.page ?? 1
                const perPage = this.$route.query.perPage ?? 12
                this.perPage = perPage
                const priceFrom = this.$route.query.price_from ?? ''
                const priceTo = this.$route.query.price_to ?? ''
                const searchQuery = this.$route.query.search ?? ''
                const listing_type = this.$route.query.listing_type ?? ''
                const region = this.$route.query.region ?? ''


                const pageQueryParam = (page ? `&page=${page}&` : '')
                const perPageQueryParam = (perPage ? `&perPage=${perPage}&` : '')
                const priceFromQueryParam = (priceFrom ? `&price.gte=${priceFrom}&` : '')
                const priceToQueryParam = (priceTo ? `&price.lte=${priceTo}&` : '')
                const serachQueryParam = (searchQuery ? `&search=${searchQuery}&` : '')
                const selectedListingTypesQueryParam = (listing_type ? `&listing_type=${listing_type}&` : '')
                const regionQueryParam = (region ? `&region=${region}&` : '')

                const sortQueryParam = this.getSelectedFilterOptionQueryParam()

                const p = [
                    pageQueryParam,
                    perPageQueryParam,
                    priceFromQueryParam,
                    priceToQueryParam,
                    serachQueryParam,
                    selectedListingTypesQueryParam,
                    regionQueryParam,
                    sortQueryParam
                ]

                return p.join('')
            },
            async loadRealEstates() {
                const thisIns = this
                thisIns.showLoader = true

                const queryParams = this.getQueryParams()

                await thisIns.$http.get(`/api/user/v1/es/real_estates/?${queryParams}`)
                    .then(res => {
                        this.realEstateItems = res.data.items ?? []
                        this.rows = this.realEstateItems.length
                        this.pagination = res.data.pagination

                        const minPrice = Number(res.data.min_price ?? 0)
                        const maxPrice = Number(res.data.max_price ?? 0)
                        if (!this.created) {
                            // when a page is first loaded, it is checked to see if they already exist price_from and price_to in URL
                            // if exist already call ES again without conditions and get min and max price of all c2c items
                            // we need this to filter the price in the slider
                            if (this.$route.query.price_from && this.$route.query.price_to) {
                                thisIns.$http.get('/api/user/v1/es/real_estates/')
                                    .then(res => {
                                        const min = Number(res.data.min_price ?? 0) * 1
                                        const max = Number(res.data.max_price ?? 0) * 1

                                        const minPriceFromUrl = Number(this.$route.query.price_from)
                                        const maxPriceFromUrl = Number(this.$route.query.price_to)
                                        
                                        this.sliderValues.value = [minPriceFromUrl, maxPriceFromUrl]
                                        this.sliderValues.min = min
                                        this.sliderValues.max = max
                                    })
                                    .catch(error => {
                                        thisIns.$printError(JSON.stringify(error))
                                        thisIns.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                                    })
                            } else {
                                this.sliderValues.value = [minPrice, maxPrice]
                                this.sliderValues.min = minPrice
                                this.sliderValues.max = maxPrice
                            }
                        }
                        
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju nepremičnin je prišlo do napake!\n${error.message}`)
                        // thisIns.showLoader = false
                    }).finally(() => {
                        thisIns.showLoader = false
                        thisIns.created = true
                    })
            },
         
            prev() {
                this.pagination.current_page = this.pagination.prev_page
            },
            next() {
                this.pagination.current_page = this.pagination.next_page
            },
            onListingTypeSelected(selectedListingTypes) {
                this.selectedListingTypeIDs = selectedListingTypes

                this.current_page = 1
                this.loadRealEstates()
            },
            async onPriceSliderChange(sliderValues) {
                this.backForwardNavigation = false
                // this.$router.replace({'price_from': sliderValues.value_from, 'price_to': sliderValues.value_to})

                let valuePrevFrom = 0
                let valuePrevTo = 0

                if (this.$route.query) {
                    valuePrevFrom = parseInt(this.$route.query.price_from)
                    valuePrevTo = parseInt(this.$route.query.price_to)
                }

                if (valuePrevFrom !== sliderValues.value_from || valuePrevTo !== sliderValues.value_to) {
                    const query = Object.assign({}, this.$route.query)
                    query.price_from = sliderValues.value_from
                    query.price_to = sliderValues.value_to
                    this.$router.replace({ query })
                    // this.$router.push({'path': this.$route.fullPath, 'query': {'price_from': sliderValues.value_from, 'price_to': sliderValues.value_to}})
                } else {
                    return
                }


                // this.$refs.sliderRefRealEstate.sliderMinInput = sliderValues.value_from
                // this.$refs.sliderRefRealEstate.sliderMaxInput = sliderValues.value_to

                await this.loadRealEstates()
                this.backForwardNavigation = true

            },
            async removeFilters() {
                this.selectedListingTypeIDs = []
                this.$refs.listing_type.check = []
                this.created = false
                this.$refs.sliderRefRealEstate.firstSliderLoad = true
                await this.$router.replace({ })
                this.selectedFilterOption = this.filterOptions[0].value
                await this.loadRealEstates()
                this.created = true
            }
        },
        async mounted() {
            this.selectedFilterOption = this.filterOptions[0].value
            await this.loadRealEstates()
            this.created = true
        },
        watch: {
            // async '$route.query'(query_params) {
                
            //     if (!this.backForwardNavigation) {
            //         return
            //     }

            //     let min = 0
            //     let max = 0

            //     // We set slider and input to right values
            //     if (query_params && Object.keys(query_params).length === 0) {
            //         min = this.sliderValues.min
            //         max = this.sliderValues.max
            //     } else {
            //         min = query_params.price_from
            //         max = query_params.price_to
            //     }

            //     this.$refs.sliderRefRealEstate.sliderMinInput = min
            //     this.$refs.sliderRefRealEstate.sliderMaxInput = max
            //     this.sliderValues.value = [min, max]

            //     this.loadRealEstates()
            // },
            'pagination.current_page'() {
                if (!this.created) return
                this.$router.push({'path': this.$route.fullPath, 'query': {'page': this.pagination.current_page, 'perPage': this.pagination.per_page = 12}})
                this.loadRealEstates()
            },
            async 'selectedFilterOption'(val, oldVal) {
                const value = val.split('=')
                const valueOld = oldVal.split('=')


                // deleting all filter options from URL
                const query = Object.assign({}, this.$route.query)
                for (const filterOption of this.filterOptions) {
                    const split = filterOption.value.split('=')
                    delete query[split[0]]
                }
                
                // if it's first option (sort by date_posted ascending) selected (default value)
                // then don't show it in URL
                if (val !== oldVal)  {

                    if (val === 'date_posted.sort=-1') {
                        delete query[valueOld[0]]
                    } else {
                        query[value[0]] = value[1]
                    }

                    this.$router.replace({query})
                }

                // after sort change, set current page to 1 (if it's not 1)
                // if pagination is null, then just load ads
                if (this.pagination && this.pagination.current_page !== 1) this.pagination.current_page = 1
                else await this.loadRealEstates()
            },
            '$route.query.search'(val) {
                if (val) {
                    this.loadRealEstates()
                }
            }
        }
    }
</script>

<style scoped>

</style>

<style>
 .realestate-button-search{
    font-size: 12px;
 }

 .realestate-button-search:hover, .realestate-button-search:active,.realestate-button-search:focus{
    font-size: 12px;
    box-shadow: none !important;
    background: #72A5D8 !important;
 }

.top-navbar{
    position: absolute;
    top: -77px;
    right: 0px;
    width: 75%;
}

@media only screen and (max-width: 1199px) {
  .top-navbar{
    position: relative;
    top: 0px;
    right: 0px;
    width: 100%;
   }
}

.filter {
  background-color: #E6E6E6;
  padding: 10px;
}
#select {
  border-radius: 0;
}
#realEstatePagination.b-pagination-pills .page-item .page-link {
  /* border: 2px solid #F8F8F8; */
  border-radius: 50% !important;
  /* background-color: #F8F8F8 !important; */
  font-weight: bold;
}

#realEstatePagination.b-pagination .page-item.active .page-link {
  background-color: #72A5D8 !important;
  border: none;
}
#realEstatePagination.b-pagination .page-item.active {
  background: transparent;
}
#realEstatePagination.b-pagination-pills .page-item.active .page-link {
  background-color: #BFD42E !important;
}
 .carousel-indicators li {
     background-color: black;
 }

</style>
